/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Flexbox permet une mise en page CSS facile et rapide. Il est un incontournable à connaître pour tout développeur Front-End, qui se respecte."), "\n", React.createElement(_components.p, null, "Aujourd’hui, ", React.createElement(_components.strong, null, "flexbox"), " est probablement l’outil que j’utilise le plus. Dans chaque application web, je l’utilise, et c’est certainement le cas pour la quasi-totalité des développeurs front."), "\n", React.createElement(_components.p, null, "Pourtant, au moment de découvrir cet outil, ce fut laborieux, il était censé me faciliter la vie, mais, comme chaque nouveauté, il semble toujours nous compliquer la vie pendant l’apprentissage. On remet en question la pertinence de cette nouvelle compétence et on pense abandonner."), "\n", React.createElement(_components.p, null, "Malgré cela, on continue parce qu’on sait au fond de nous que cela nous facilitera la vie. De mon côté, l’apprentissage, c’est passé par des projets sous forme de test, mais aussi, par un site que vous connaissez sûrement, ", React.createElement(_components.a, {
    href: "https://flexboxfroggy.com/#fr"
  }, "flexbox froggy"), "."), "\n", React.createElement(_components.p, null, "Il faut se souvenir qu’avant ", React.createElement(_components.strong, null, "flexbox"), ", on devait utiliser les float et le positionnement. La mise en page était laborieuse pour tout le monde, même si cela fonctionnait bien pour les meilleures."), "\n", React.createElement(_components.p, null, "Mais outre cela, pourquoi j’aime tant ", React.createElement(_components.strong, null, "flexbox"), " ?"), "\n", React.createElement(_components.p, null, "Flexbox apporte de très nombreux avantages. Une mise en page facile et rapide, une syntaxe toute aussi simple, il facilite aussi sous certains aspects le responsive. Si vous voulez afficher les éléments en colonne, flexbox le fait facilement. Si vous voulez mettre un espace entre chaque élément, flexbox le fait facilement. Si vous voulez aligner verticalement et horizontalement un élément, flexbox le fait facilement…"), "\n", React.createElement(_components.p, null, "Alors, qu’on s’entendent bien, ", React.createElement(_components.strong, null, "Flexbox"), " a quelques défauts, mais sa simplicité et le champs des possibles qu’il offre les compenses aisément."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
